:root {
  /* colors variables */
  --color_primary: #157EFB; 
  --color_primary_hover: #1DA6E5; 
  --color_primary_hover__dark: #0368e0;
  --color_primary_semi: #b7d6fc;
  --color_black: #212121;
  --color_darkGray: #606060;
  --color_gray: #ACACAC; --color_gray_hover: rgba(172, 172, 172, 0.75);
  --color_lightGray2: #DEDEDE;
  --color_lightGray3: #F7F7F7;
  --color_lightGray: #EEEEEE;
  --color_white: #FFF;
  --color_green: #00AB26; --color_green_hover: rgba(0, 171, 38, 0.75);
  --color_darkRed: #D0021B; --color_darkRed_hover: rgba(208, 2, 27, 0.75);
  --color_lightRed: #FF6C6C;
  --color_orange: #F89540; --color_orange_hover: #FFB85C;
  --color_orange_semi: #F8954080;
  --color_yellow_light: rgba(255, 233, 0, 0.2);
  --color_yellow_semi: rgba(255, 233, 0, 0.5);
  --color_yellow_frame: #ffeb3b38;

  /* font-size variables */
  --fontSize_h1: 35px;
  --fontSize_h2: 21px;
  --fontSize_18: 19px;
  --fontSize_h3: 17px;
  --fontSize_h4: 15px;
  --fontSize_tab: 15px;
  --fontSize_standart: 13px;
  --fontSize_tip: 11px;
  --fontSize_button: 13px;
  --fontSize_bigPopup: 24px;
  --fontSize_readable: 15px;

  --fontSize_frameSmall: 10px;
  --fontSize_frameNormal: 12px;

  /* box-shadow variables */
  --shadow_big: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  --shadow_menu: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
  --shadow_alert: 0 1px 5px 0 rgba(0, 0, 0, 0.15);

  /* buttons variables */
  --btn_borderRadius_small: 4px;
  --btn_borderRadius_primary: 5px;
  --btn_borderRadius_big: 6px;
  --btn_padding: 2px 10px;
}

.wrapper {
  /*min-width: 1346px;*/
  max-width: 2520px;
  margin: 0 auto;
  min-height: 100vh;
  font-family: var(--fontFamily);
  color: var(--color_black);
  position: relative;
}
